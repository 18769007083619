<template>
  <div class="addPoster">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加/编辑列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="所在位置">
        <el-select v-model="posterValue" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.placeId"
            :label="item.placeName"
            :value="item.placeId"
          >
          </el-option>
        </el-select>
        <el-select v-model="formList.placeCode" placeholder="请选择" v-show="posterValue">
          <el-option
            v-for="item in placeOptions"
            :key="item.placeCode"
            :label="item.placeName"
            :value="item.placeCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <div style="color: red; padding-left: 20px">{{ placeRemark }}</div>
      </el-form-item>
      <el-form-item label="广告标题">
        <el-input v-model="formList.title" placeholder="请输入广告标题"></el-input>
      </el-form-item>
      <el-form-item label="副标题">
        <el-input
          v-model="formList.secondTitle"
          placeholder="请输入广告副标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="广告图片">
        <send-image
          :images="imgUrl"
          v-if="Number(id) == 0 || (Number(id) > 0 && formList.placeCode !== '')"
          @addImageStr="addImageStr"
        ></send-image>
      </el-form-item>
      <el-form-item label="">
        <pre style="color: red">
          图片建议尺寸:
          （1）首页-轮播图345*172px；首页-入口375*68px；
          （2）拼团列表页370*126px；
          （3）秒杀列表页370*126px；
          （4）精选商城375*166px；
          （5）万小店259*104px；
          （6）广场-轮播图375*140px；广场-热门广告项167*167px；
          （7）弹窗尺寸716*935px；
        </pre>
      </el-form-item>
      <el-form-item label="广告状态">
        <el-radio v-model="radio" label="1">显示</el-radio>
        <el-radio v-model="radio" label="2">隐藏</el-radio>
      </el-form-item>
      <el-form-item label="	跳转类型">
        <el-select v-model="skipType" @change="skipTypeId = null" placeholder="请选择">
          <el-option
            v-for="item in skipTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跳转ID" v-show="skipTypeList.includes(skipType)">
        <el-input v-model="skipTypeId" placeholder="请输入跳转ID"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import {
  posterPlaceList,
  selectAllByFatherId,
  posterPlaceDetail,
} from "../../../api/posterPlace";
import { addOrEditPoster, posterDetail } from "../../../api/poster";
import { sendFile } from "../../../api/sendFile";
import sendImage from "../../../components/sendImage.vue";
export default {
  components: { sendImage },
  name: "addPoster",
  props: {
    id: {
      typoe: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      skipTypeList: [2, 4, 5,6, 9, 10, 11, 12, 14, 15, 16,17,18,20,21,24,25],
      options: [],
      placeOptions: [],
      posterValue: null,
      radio: "1",
      placeRemark: "",
      skipTypeOptions: [
        { value: 0, label: "不跳转" },
        { value: 1, label: "酒店列表页" },
        { value: 2, label: "酒店详细页面" },
        { value: 3, label: "门票列表页" },
        { value: 4, label: "门票详细页面" },
        { value: 5, label: "线路列表页" },
        { value: 6, label: "线路详细页面" },
        { value: 7, label: "拼团首页" },
        { value: 8, label: "秒杀首页" },
        { value: 9, label: "精选商城-商品详情" },
        { value: 10, label: "拼团详情" },
        { value: 11, label: "秒杀详情 " },
        { value: 12, label: "活动页" },
        { value: 13, label: "优惠券列表" },
        { value: 14, label: "关于我们" },
        { value: 15, label: "万小店门票详细页" },
        { value: 16, label: "万小店酒店详情页" },
        { value: 17, label: "美食列表" },
        { value: 18, label: "美食详情" },
        { value: 19, label: "万旅年卡" },
        { value: 20, label: "万旅年卡详情页" },
        { value: 21, label: "游戏" },
        { value: 22, label: "超级礼包" },
        { value: 23, label: "万小店首页" },
        { value: 24, label: "线路分类" },
        { value: 25, label: "美食分类" },
      ],
      formList: {
        placeCode: "",
        title: "",
        secondTitle: "",
        status: 1,
      },
      recommendId: Number(this.id),
      imgUrl: "",
      skipType: 0,
      skipTypeId: null,
    };
  },
  computed: {
    placeRemark1() {
      var arr = this.placeOptions.filter((item) => {
        return item.placeCode == this.formList.placeCode;
      });
      return arr[0].remark;
    },
  },
  watch: {
    posterValue: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: Number(this.posterValue),
      });
      this.placeOptions = data.data;
    },
    radio: function () {
      this.formList.status = Number(this.radio);
    },
    "formList.placeCode": {
      handler: function (newValue, oldValue) {
        var arr = this.placeOptions.filter((item) => {
          return item.placeCode == this.formList.placeCode;
        });
        this.placeRemark = arr[0].remark;
      },
      deep: true,
    },
  },
  created() {
    this.getposterPlaceList();
    if (Number(this.id) != 0) {
      this.getposterDetail();
    }
  },
  methods: {
    //通过id获取数据详情
    async getposterDetail() {
      const { data } = await posterDetail({
        recommendId: Number(this.id),
      });
      this.formList.posterValue = data.data[0].fatherPlaceCode;
      this.formList.title = data.data[0].title;

      this.formList.secondTitle = data.data[0].secondTitle;

      this.imgUrl = data.data[0].imgUrl;
      this.radio = data.data[0].status + "";
      this.formList.status = data.data[0].status;
      this.skipType = data.data[0].skipType;
      this.skipTypeId = data.data[0].skipTypeId;
      this.posterValue = data.data[0].fatherPlaceId;
      this.formList.placeCode = data.data[0].placeCode;
    },
    async getposterPlaceList() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      this.options = data.data;
    },
    async onSave() {
      for (const key in this.formList) {
        if (key !== "secondTitle") {
          if (this.formList[key] === null || this.formList[key] === "") {
            return this.$message.error("请把数据填写完整");
          }
        }
      }
      if (this.imgUrl === "" || this.imgUrl === null) {
        return this.$message.error("请把数据填写完整");
      }

      if (this.skipTypeList.includes(this.skipType) && this.skipTypeId == null) {
        return this.$message.error("在类型为详细页面的时候请务必传跳转ID");
      }
      // return
      const { data } = await addOrEditPoster({
        ...this.formList,
        recommendId: this.recommendId,
        skipType: this.skipType,
        skipTypeId: this.skipTypeId,
        imgUrl: this.imgUrl,
      });
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        this.$router.go(-1);
      } else {
        this.$message.error(data.msg);
      }
    },
    addImageStr(image) {
      this.imgUrl = image;
    },
  },
};
</script>
<style lang="less" scoped>
.addPoster {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.el-form {
    margin-top: 50px;
    .el-form-item__content {
      display: flex;
      .el-select {
        width: 210px !important;
        .el-input {
          width: 100%;
        }
      }
      .el-radio {
        margin-top: 10px;
      }
      .img {
        position: relative;
        height: 100px;
        width: 100px;
        margin: 20px 20px;
        img {
          height: 100px;
          width: 100px;
          border: 1px solid #c0ccda;
        }
        div {
          position: absolute;
          top: 0;
          right: 2px;
          height: 10px;
          width: 10px;
          z-index: 100;
        }
      }
      .addImg {
        display: inline-block;
        width: 100px;

        height: 100px;
        border: 1px dashed #c0ccda;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        margin-top: 20px;
        i {
          width: 50px;
          height: 50px;
          font-size: 20px;
          color: #c0ccda;
        }
      }
    }

    .el-input {
      width: 430px;
    }
  }
}
</style>
